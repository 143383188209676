<template>
  <div>
    <EditDialog
      width="60%"
      :isReturn="true"
      v-loading="loading"
      @closeFn="$emit('update:isBusinessCostShow', false)"
      :isShow.sync="businessCostIsShow"
    >
      <div slot="title" class="title">商务费用结算调整</div>
      <div slot="content" class="content" v-loading="loading">
        <el-descriptions
          style="margin-top: 10px; margin-bottom: 10px"
          title="商务基础信息"
          direction="vertical"
          :column="5"
          border
        >
          <template slot="extra">
            <el-button type="success" @click="isPerformancelog = true" size="small"
              >历史记录</el-button
            >
          </template>
          <el-descriptions-item label="商务名称" :span="2">
            {{ form.businessName || '' }}
          </el-descriptions-item>
          <el-descriptions-item label="商务类型" :span="1">
            {{ form.businessType | dict(dictData.businessType) }}
          </el-descriptions-item>
          <el-descriptions-item label="项目来源" :span="1">
            {{ form.projectSource | dict(dictData.projectSource) }}
          </el-descriptions-item>
          <el-descriptions-item label="业务种类" :span="1">
            {{ form.businessLines | dict(dictData.businessLines) }}
          </el-descriptions-item>

          <el-descriptions-item label="业主单位" :span="3">
            {{ form.ownerUnit || '' }}
          </el-descriptions-item>
          <el-descriptions-item label="项目承接公司" :span="1">
            {{ form.companyType | dict(dictData.companyType) }}
          </el-descriptions-item>
          <el-descriptions-item label="实际商务负责人" :span="1">
            {{ form.principalUserName }}
          </el-descriptions-item>
        </el-descriptions>

        <div class="content-maincon">
          <el-descriptions
            style="margin-top: 10px; margin-bottom: 10px; width: 100%"
            direction="vertical"
            :column="5"
            border
          >
            <template slot="title">
              <span style="font-size: 18px; color: black; font-weight: 700">商务费用结算</span>
            </template>
            <template slot="extra">
              <span style="font-size: 17px; color: gray">单位：元</span>
            </template>
            <el-descriptions-item
              label="我方合同金额"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <span class="content-left-row-content">
                {{ form.newData.ourContractAmount | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                <div>税费总计</div>
                <div style="font-size: 12px; color: red; margin-top: 5px">
                  包含增值税、附加税、印花税
                </div>
              </template>
              <span class="content-left-row-content">
                {{ form.newData.taxesFee | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              label="招投标费用"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <span class="content-left-row-content">
                {{ form.newData.tenderFee | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              label="预计外部协作资金"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <span class="content-left-row-content">
                {{ form.newData.exteriorBusinessCosts | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              label="实际外部协作资金"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <span class="content-left-row-content">
                {{ form.newData.actualExteriorBusinessCosts | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              :span="2"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                <div>预计可支配金额</div>
                <div style="font-size: 12px; color: red; margin-top: 5px">
                  预计可支配金额 = 我方合同金额 - 税费 - 招投标费用 - 预计外部协作资金
                </div>
              </template>
              <span class="content-left-row-content">
                {{ form.newData.predictDisposableProjectExpense | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label"> 到款金额 </template>
              {{ form.newData.receivedAmount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                <div>
                  内部生产资金
                  <el-button
                    style="margin-left: 20px"
                    type="primary"
                    size="mini"
                    @click="showNBSCFY"
                    :loading="isNBSCFYButtonLoading"
                    >查看比例</el-button
                  >
                </div>
                <div style="margin-top: 5px">
                  <span style="font-size: 12px; color: red">
                    内部生产资金 = 项目可支配资金 X 生产费用比例
                  </span>
                </div>
              </template>
              <span class="content-left-row-content">
                {{ form.newData.productionCost | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                <div>
                  外协生产资金

                  <el-button
                    style="margin-left: 8px"
                    type="primary"
                    size="mini"
                    @click="showWXSCFY"
                    :loading="isWXSCFYButtonLoading"
                    >查看详情</el-button
                  >
                </div>
              </template>
              <span class="content-left-row-content">
                {{ form.newData.projectOutsourceByBusiness | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              :span="2"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                <div>结算可支配资金</div>
                <div style="font-size: 12px; color: red; margin-top: 5px">
                  结算可支配资金 = （预计可支配金额 - 内部生产资金 - 外协生产资金）X 25%
                </div>
              </template>
              <span class="content-left-row-content">
                {{ form.newData.interiorBusinessPerformance | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                <div>商务部管理资金</div>
                <div style="margin-top: 5px">
                  <span style="font-size: 12px; color: red">
                    商务部管理资金 = 结算可支配资金 X 20%
                  </span>
                </div>
              </template>
              <span class="content-left-row-content">
                {{ form.newData.businessDeptPerformance | applyAmount }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                <div>商务团队费用</div>
                <div style="margin-top: 5px">
                  <span style="font-size: 12px; color: red">
                    商务团队费用 = 结算可支配资金 X 80%
                  </span>
                </div>
              </template>
              <span class="content-left-row-content">
                {{ form.newData.businessTeamPerformance | applyAmount }}</span
              >
            </el-descriptions-item>

            <el-descriptions-item
              :span="1"
              label-class-name="my-label projectBGC"
              content-class-name="my-content projectBGC"
            >
              <template slot="label">
                <span style="color: red">*</span>
                实际绩效金额
              </template>
              <template v-if="permission(['EDIT_BUSINESS_DISPOSABLE_EXPENSES'])">
                <el-input
                  @input="inputFn('actualPerformance')"
                  @focus="focusFn('actualPerformance')"
                  @blur="blurFn('actualPerformance')"
                  v-model="form.newData.actualPerformance"
                ></el-input>
              </template>
              <template v-else>
                {{ form.newData.actualPerformance }}
              </template>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button
          type="success"
          v-loading="loading"
          v-if="permission(['EDIT_BUSINESS_DISPOSABLE_EXPENSES'])"
          @click="onEditactualPerformance"
          >保存</el-button
        >
        <el-button type="info" plain @click="$emit('update:isBusinessCostShow', false)"
          >返回</el-button
        >
      </div>
    </EditDialog>

    <!-- 查看内部生产资金比例 -->
    <EditDialog width="60%" :isShow.sync="NBSCFY_ratio_show">
      <div slot="title" class="title">内部生产资金比例</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          当前生产费用比例 ：
          <span style="color: red">{{ NBSCFY_ratio_data.productionCostSumRatio * 100 }}%</span>
        </div>
        <div class="below-content">
          <div style="width: 50%">
            <el-table
              :data="NBSCFY_ratio_data.productionCostRatioLogByProjectList"
              style="width: 100%"
              border
              row-key="projectId"
            >
              <el-table-column type="expand" width="30">
                <template slot-scope="props">
                  <div
                    style="
                      font-size: 14px;
                      color: black;
                      font-weight: 700;
                      margin-bottom: 5px;
                      margin-left: 20px;
                      margin-top: 10px;
                    "
                  >
                    历史记录：
                  </div>
                  <el-table
                    :data="props.row.productionCostRatioLogList"
                    border
                    style="width: 92%; margin-bottom: 10px; margin-left: 20px"
                  >
                    <el-table-column
                      type="index"
                      align="center"
                      width="30"
                      :show-overflow-tooltip="false"
                    ></el-table-column>
                    <el-table-column
                      label="业务种类"
                      prop="businessLines"
                      align="center"
                      width="105"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.businessLines | dict(dictData.businessLines) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="项目来源"
                      prop="projectSource"
                      align="center"
                      width="85"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.projectSource | dict(dictData.projectSource) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="productionCostRatio"
                      label="比例"
                      width="70"
                      align="center"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.productionCostRatio * 100 }}%
                      </template>
                    </el-table-column>
                    <el-table-column prop="createdDate" label="更改日期" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.createdDate | dateFormat }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                label="项目名称"
                prop="projectName"
                align="center"
                :show-overflow-tooltip="false"
              >
              </el-table-column>
              <el-table-column
                label="生产费用占比"
                prop="productionCostRatioByProject"
                align="center"
                width="80"
                class-name="height-line"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  {{ scope.row.productionCostRatioByProject * 100 }}%
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="padding: 0 0 0 5px; flex: 1">
            <img src="@/assets/简化生产费用比例.png" alt="生产费用比例说明" width="96%" />
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="NBSCFY_ratio_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 查看项目外协费用 -->
    <EditDialog width="60%" :isShow.sync="WXSCFY_detail_show">
      <div slot="title" class="title">外协生产资金</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          目前外协生产总费用 ：
          <span style="color: red">{{ WXSCFY_detail_data.outsourceSumCost }}元</span>
        </div>
        <el-table
          :data="WXSCFY_detail_data.projectOutsourceLogByProjectList"
          style="width: 100%"
          border
        >
          <el-table-column type="expand" width="30">
            <template slot-scope="props">
              <div
                style="
                  font-size: 14px;
                  color: black;
                  font-weight: 700;
                  margin-bottom: 5px;
                  margin-left: 20px;
                  margin-top: 10px;
                "
              >
                外协单位信息：
              </div>
              <el-table
                :data="props.row.projectOutsourceLogByName"
                border
                style="width: 96%; margin-bottom: 10px; margin-left: 20px"
              >
                <el-table-column type="expand" width="30">
                  <template slot-scope="props">
                    <div
                      style="
                        font-size: 14px;
                        color: black;
                        font-weight: 700;
                        margin-bottom: 5px;
                        margin-left: 20px;
                        margin-top: 10px;
                      "
                    >
                      历史记录：
                    </div>
                    <el-table
                      :data="props.row.projectOutsourceLogRespList"
                      border
                      style="width: 96%; margin-bottom: 10px; margin-left: 20px"
                    >
                      <el-table-column
                        label="序号"
                        type="index"
                        align="center"
                        width="100"
                        :show-overflow-tooltip="false"
                      ></el-table-column>
                      <el-table-column prop="cost" label="外协生产资金" align="center" width="180">
                        <template slot-scope="scope"> {{ scope.row.outsourceCost }}元 </template>
                      </el-table-column>
                      <el-table-column prop="date" label="更改日期" align="center">
                        <template slot-scope="scope">
                          {{ scope.row.createdDate | dateFormat }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="outsourceName" label="外协单位" align="center">
                </el-table-column>
                <el-table-column
                  prop="outsourceSumCostByName"
                  label="外协生产资金"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope"> {{ scope.row.outsourceSumCostByName }}元 </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <!-- <el-table-column label="序号" prop="id" align="center" width="100"> </el-table-column> -->
          <el-table-column label="项目名称" prop="projectName" align="center"> </el-table-column>
          <el-table-column label="业务种类" prop="businessLines" align="center" width="170">
            <template slot-scope="scope">
              {{ scope.row.businessLines | dict(dictData.businessLines) }}
            </template>
          </el-table-column>
          <el-table-column label="项目来源" prop="projectSource" align="center" width="170">
            <template slot-scope="scope">
              {{ scope.row.projectSource | dict(dictData.projectSource) }}
            </template>
          </el-table-column>
          <el-table-column
            label="项目外协生产资金合计"
            prop="outsourceSumCostByProject"
            align="center"
            width="170"
            class-name="height-line"
          >
            <template slot-scope="scope"> {{ scope.row.outsourceSumCostByProject }}元 </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="WXSCFY_detail_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 变更记录 -->
    <Performancelog :isShow.sync="isPerformancelog" :options="form" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  numberToCurrencyNo,
  delcommafy,
  changeMoneyToChinese,
  setBusinessPerformance,
} from '@/util/jsencrypt'
export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    ProjectPerformanceDetailDialog: () => import('@/components/ProjectPerformanceDetailDialog.vue'),
    Performancelog: () => import('@/components/businessPerformance/business-performance-log.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isBusinessCostShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      dictData: {
        projectSource: [],
        businessLines: [],
        businessType: [],
        companyType: [],
      },
      businessCostIsShow: false, //结算调整弹窗
      isPerformancelog: false, //变更记录弹窗
      form: {
        changeFieldList: [], //变更字段
        newData: {}, //	新数据
        oldData: {}, //	旧数据
      },
      originalForm: {},
      NBSCFY_ratio_show: false, // 内部生产资金弹框显示隐藏
      isNBSCFYButtonLoading: false,
      NBSCFY_ratio_data: {},

      WXSCFY_detail_data: {},
      isWXSCFYButtonLoading: false,
      WXSCFY_detail_show: false, //外协生产资金详情弹框显示隐藏
      businessCostInfo: {}, //商务结算调整
      businessPerformanceInfo: {}, //获取商务比列信息
      actualPerformanceShow: false, //商务绩效比例是否归档
    }
  },
  computed: {
    ...mapState({}),
  },
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  watch: {
    isBusinessCostShow: {
      immediate: true,
      handler: function (val) {
        this.businessCostIsShow = val
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler: function (options) {
        if (options && options.id) {
          this.loading = true
          this.$api.businessManage
            .selectAdjustmentDetailByBusinessId(options.id)
            .then(res => {
              if (res.data) {
                this.form = res.data
                this.originalForm = res.data.oldData
                this.form.changeFieldList = this.form.changeFieldList
                  ? this.form.changeFieldList
                  : []
                this.form.newData.actualPerformance =
                  numberToCurrencyNo(this.form.newData.actualPerformance) || 0

                if (
                  res.data.performanceStatus === 100 &&
                  this.permission(['EDIT_BUSINESS_DISPOSABLE_EXPENSES'])
                ) {
                  this.actualPerformanceShow = true
                  this.$api.businessPerformance
                    .selectBusinessPerformanceInfoByBusinessId(options.id)
                    .then(res => {
                      if (res.data) {
                        this.businessPerformanceInfo = res.data
                        res.data.businessPerformanceList.forEach(item => {
                          item.userAssignment = []
                          res.data.userAssignmentMap.forEach(userItem => {
                            if (item.ruleCode === userItem.ruleCode) {
                              item.userAssignment.push(userItem)
                            }
                          })
                        })
                        this.businessPerformanceInfo.performanceArr =
                          res.data.businessPerformanceList
                        this.businessPerformanceInfo.id =
                          res.data?.businessPerformanceAdjustment?.businessManageId
                      }
                    })
                    .catch(err => {
                      console.log(err)
                    })
                } else {
                  this.actualPerformanceShow = false
                }
              }

              this.loading = false
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        }
      },
    },
  },
  methods: {
    /** 值改变触发 "申请金额" 文本框 **/
    inputFn(e) {
      this.form.newData[e] = String(this.form.newData[e])
        .replace(/[^\d.-]/g, '') // 允许数字、负号和小数点
        .replace(/(?!^)-/g, '') // 移除除了开头的其他负号
        .replace(/^(\-)?(0|[1-9]\d*)(\.\d{0,2})?$/, '\$1\$2\$3') // 确保格式正确
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '\$1\$2.\$3') // 处理小数点后两位的限制
      this.$forceUpdate()
    },

    /** 获得焦点触发 "申请金额" 文本框 **/
    focusFn(e) {
      this.form.newData[e] = String(this.form.newData[e]).replace(/,/g, '')

      this.$forceUpdate()
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurFn(e) {
      this.form.newData[e] = Number(this.form.newData[e]).toLocaleString()
      this.$forceUpdate()
    },

    // 内部生产资金弹窗
    showNBSCFY() {
      this.isNBSCFYButtonLoading = true
      this.NBSCFY_ratio_data = {}
      this.$api.productionCostRatioLog
        .productionCostRatioLogByBusiness({ businessId: this.form.newData.id })
        .then(res => {
          this.isNBSCFYButtonLoading = false
          this.NBSCFY_ratio_data = res.data
          this.NBSCFY_ratio_show = true
        })
        .catch(err => {
          this.isNBSCFYButtonLoading = false
          console.log(err)
        })
    },
    // 外协费用弹窗
    showWXSCFY() {
      this.isWXSCFYButtonLoading = true
      this.WXSCFY_detail_data = {}
      this.$api.productionCostRatioLog
        .projectOutsourceLogByBusiness({ businessId: this.form.newData.id })
        .then(res => {
          this.isWXSCFYButtonLoading = false
          this.WXSCFY_detail_data = res.data
          this.WXSCFY_detail_show = true
        })
        .catch(err => {
          this.isWXSCFYButtonLoading = false
          console.log(err)
        })
    },
    // 修改实际绩效金额二次确认
    onEditactualPerformance() {
      let str = ''
      if (this.actualPerformanceShow) {
        str = '修改后的商务绩效将同步显示给商务成员。'
      } else {
        str = '当前商务团队成员绩效比例尚未确定，成员无法查看绩效金额。'
      }
      this.$confirm(`${str}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.projectCostSubmit()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    // 保存
    async projectCostSubmit() {
      this.loading = true
      let editArr = []
      this.form.newData.actualPerformance = Number.parseFloat(
        delcommafy(this.form.newData.actualPerformance)
      )
      for (const key in this.originalForm) {
        if (key === 'actualPerformance' && this.form.newData.hasOwnProperty(key)) {
          const originalValue = this.originalForm[key]
          const newValue = this.form.newData[key]
          if (originalValue !== newValue) {
            editArr.push(key)
          }
        }
      }
      const obj = {
        changeFieldList: editArr,
        newData: this.form.newData,
        oldData: this.originalForm,
      }
      if (this.form.professionalId) {
        obj.professionalId = this.form.professionalId
      }
      const update = await this.$api.businessManage.updateAdjustment(obj)
      //  保存成功-分配人员比列金额
      if (update.code == 200 && this.businessPerformanceInfo.id) {
        setBusinessPerformance(
          this.form.newData.actualPerformance,
          this.businessPerformanceInfo.performanceArr,
          this.businessPerformanceInfo
        )
      }
      this.$message.success('修改成功')
      this.$api.businessManage
        .selectAdjustmentDetailByBusinessId(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data.deepClone()
            this.originalForm = res.data.newData.deepClone()
            this.form.changeFieldList = this.form.changeFieldList ? this.form.changeFieldList : []
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    getType() {
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.my-label {
  width: 20%;
}
/deep/.my-content {
  width: 20%;
}
/deep/.projectBGC {
  //背景色
  background-color: #d0f6fd !important;
  border: 1px solid #ddd !important;
}
.content-right-view {
  display: flex;
  justify-content: space-between;
}
.content-text {
  font-weight: 900;
  font-size: 20px;
  text-align: left;
}
.below-content {
  display: flex;
  justify-content: space-between;
}

::v-deep .highlight {
  background: rgb(241, 212, 158) !important;
}

/deep/.el-table tbody tr:hover > td {
  background-color: transparent;
}
.content-maincon {
  display: flex;
  justify-content: center;
  width: 100%;
  .content-left-row-content {
    line-height: 40px;
  }
  .vertical-dashed-line {
    margin: 0 5px;
    border-left: 3px dashed #ccc; /* 虚线的样式和颜色 */
    height: 87%; /* 虚线的高度 */
  }
  .cost-btn {
    height: 30px;
    margin-top: 5px;
  }
}

// input type为number，只输入数字
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
